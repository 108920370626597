._{
    padding:0 20px;
    @media (min-width:768px) {
        width:450px;
    }
    .form_container{
        background-color: var(--white);
        box-shadow: 0px 4px 8px 8px rgba(174, 174, 174, 0.2);
        padding:50px 30px;
        border-radius: 5px;
        .form_header{
            text-align:left;
            margin-bottom:30px;
           
            @media (min-width:768px) {
                text-align:center;
                border-bottom:1px solid #828282;
            }
            h3{
                font-size: 30px;
                font-family: var(--font-family-chivo);
                color: var(--blue);
                margin-top:0 !important;
            }
            p{
                color: #828282;
                font-size: 16px;
                font-family: var(--font-family-avenir);
                width:100%;
            }
        }
        .form_input_container{
            margin-bottom:25px;
        }
        p.form_info{
            color: #828282;
            font-size: 16px;
            font-family: var(--font-family-avenir);
        }
        .button_container{
            margin-top:20px;
            .sign_up_btn{
                width:100% !important;
                box-shadow: 0px 4px 8px rgba(34, 212, 127, 0.25);
                font-size: 20px;
            }
        }
    }
    .login_link_container{
        @media (min-width:768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            .left_line, .right_line{
                height:1px;
                width:30px;
                background-color: var(--black);
            }
        }
       
        margin-top:30px;
        border: 1px solid #2F80ED;
        p{
            text-align:center;
            font-size: 20px;
            @media (min-width:768px){
                padding-left:30px;
                padding-right:30px;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
            .login_link{
                display: inline !important;
                text-align: none !important;
                color:var(--blue);
                padding-left:0px !important;
                padding-right:0px !important;
            }
        }
    }
}
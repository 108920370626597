._,.primary,.secondary{
    flex: 1;
    padding: 1rem;
    font-family: var(--font-family-avenir);
    height: 3.5rem;
    text-align: center;
    line-height: 1.5rem;
    font-weight: bold;
    display: block;
}
.primary{
    font-family: var(--font-family-chivo);
    background: var(--primary);
    color: white;
}
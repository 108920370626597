._{
    padding:0 30px;
    @media (min-width:576px) {
        width:450px;
    }
    .container{
        background-color: var(--white);
        padding:70px 30px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        .congrats_text{
            text-align: center;
            p{
                margin-top:0 !important;
                margin-bottom: 0 !important;
                font-size: 20px;
                color:var(--green);
                font-family: var(--font-family-chivo);
                font-weight:600;
            }
        }
        .congrats_info{
            p{
                text-align: center;
                color: #828282;
            }
        }
    }
}
._,.primary,.secondary{
    flex: 1;
    padding: 1rem;
    font-family: var(--font-family-avenir);
    font-weight: bold;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    height: 3.5rem;
    line-height: 1.5rem;
    width: 100%;
    background: transparent;
    &:focus{
        border: none;
        outline: none;
    }
}
.primary{
    background: var(--primary);
    box-shadow: var(--primary-shadow);
    color: white;
    font-size: 1rem;
}
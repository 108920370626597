*{
    box-sizing: border-box;
}

.form_container{
    label.form_label{
        color: #555555;
        font-size: 16px;
    }
    input.form_input{
        background-color: var(--white);
        border:1px solid #CED0DA;
        padding-top:12px;
        padding-bottom:12px;
        border-radius: 4px;
        padding-left:20px;
        font-family: var(--font-family-avenir);
        color: #9A9FA8;
        font-size: 14px;
        margin-top:8px;
        width:100%;

        &::placeholder{
            font-family: var(--font-family-avenir);
            color: #9A9FA8;
            font-size: 14px;
        }

        &:focus{
            color: #9A9FA8;
            font-size: 14px;
            outline-color:  #CED0DA;
        }
    }
}
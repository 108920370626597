@font-face {
  font-family: 'Avenir';
  src: url(./assets/fonts/Avenir/Avenir-Light.woff) format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url(./assets/fonts/Avenir/Avenir-Book.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url(./assets/fonts/Avenir/Avenir-Roman.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url(./assets/fonts/Avenir/Avenir-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url(./assets/fonts/Avenir/Avenir-Heavy.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url(./assets/fonts/Avenir/Avenir-Black.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src: url(./assets/fonts/Muli/Muli\ Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src: url(./assets/fonts/Muli/Muli.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src: url(./assets/fonts/Muli/Muli-SemiBold.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src: url(./assets/fonts/Muli/Muli-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

:root {
  --blue: #3275CD;
  --cyan: #D5ECF6;
  --red: #FA050F;
  --green: #219653;
  --lavender: #5E77FF;
  --white: #fff;
  --black: #000;
  --light-gray: #F6F7F7;
  --gray: #828282;
  --gray-dark: #4F4F4F;
  --primary: #22D47F;
  --success: #22D47F;
  --info: #2D9CDB;
  --danger: #FA050F;
  --transparent: rgba(51, 65, 86, 0.5);
  --primary-shadow: 0px 4px 8px rgba(34, 212, 127, 0.25);
  --breakpoint-xs: 450px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-avenir: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-family-chivo: 'Chivo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-family-muli: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-avenir);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4{
  font-family: var(--font-family-chivo);
  margin: 1rem 0;
  padding: 0;
}
p{
  margin: 1rem 0;
  line-height: 24px;
}

a{
  text-decoration: none;
  color: var(--gray-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  padding: 0 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.fullscreen_loading{
  display: grid;
  height: 100vh;
  width: 100vw;
  place-content: center;
  place-items: center;
}
.fullscreen_loading::before{
  width: 50px;
  height: 50px;
  content: "";
  background: url('./assets/images/sound.gif');
  background-size: 50px;
}

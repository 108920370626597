.body{
    
    background-color: var(--cyan);
    background: linear-gradient(to bottom, rgba(38, 43, 45, 0.4) 0% ,rgba(38, 43, 45, 0.4) 50% ,var(--cyan) 50% ,var(--cyan) 100%),
    url('../../../assets/images/authentication_bg.jpg') no-repeat top center;
    background-position-y: top;
    background-repeat: no-repeat, no-repeat;
    height:100vh;
    width:100%;
    display: flex;
    flex-direction: column;
    @media (min-width:768px) {
        background: linear-gradient(to bottom, rgba(38, 43, 45, 0.4) 0% ,rgba(38, 43, 45, 0.4) calc(0.35 * 100vw) ,var(--cyan) calc(0.35 * 100vw) ,var(--cyan) 100%),
        url('../../../assets/images/authentication_bg.jpg') no-repeat top center;
        background-size: 100vw;
    }
    .header{
        display:none;
        @media (min-width:768px) {
            padding: 30px 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .button_container{
                .subscribe_button{
                    padding: 0px 30px;
                    height: 42px;
                }
            }  
        }
      
    }

    .box_container{
        overflow: hidden;
        height:100vh;
        width:100%;
        display: flex;
        justify-content: center;
        align-items:center;
        @media (min-width:768px) {
            height:80vh;
        }
    }
}
